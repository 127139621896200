













import Vue from "vue";
import mixins from "vue-typed-mixins";
import ComponentModsMixin from "@/mixins/component-mods.ts";

export default mixins(ComponentModsMixin).extend({
  baseClass: "tableText",
  defaultTheme: "main",
  defaultSize: "m",
  props: {
    tag: {
      type: String,
      default: "p"
    }
  }
});
