
import Vue, { VNode } from "vue";
import mixins from "vue-typed-mixins";
import ComponentModsMixin from "@/mixins/component-mods.ts";

export default mixins(ComponentModsMixin).extend({
  baseClass: "column",
  name: "TableCol",
  render(): VNode {
    const slots = this.$slots.default || [];
    const slotsWraps = slots.map(slot => <div class="column__textOverflow">{slot}</div>);

    const tag = this.containMod("th") ? "th" : "td";

    return (
      <tag class={this.modsClasses}>
        <div class="column__wrap">{slotsWraps}</div>
      </tag>
    );
  }
});
