












import Vue from "vue";
import { SearchSelect } from "@/ui";
import { SearchSuggestion, SearchSuggestionsType } from "@/schemas/dashboard";
import { find, matches, uniqWith, isEqual, zipObject } from "lodash";
import { Route } from "vue-router";
import { Dictionary } from "vue-router/types/router";
import { AugmentedRouterQuery, AugmentedRouterQueryValue } from "@/store/modules/routeQuery";
//TODO: вынести работу с роутом в другое место
type SearchSuggestionsTypeString = keyof typeof SearchSuggestionsType;

type SearchSuggestionsGrouped = {
  -readonly [key in SearchSuggestionsTypeString]?: string[];
};

const SUGGESTIONS_KEYS = Object.keys(SearchSuggestionsType) as SearchSuggestionsTypeString[];

interface ComponentData {
  [s: string]: any;
  selected: SearchSuggestion[];
}

export default Vue.extend({
  data: (): ComponentData => ({
    selected: []
  }),
  computed: {
    availableSuggestions(): SearchSuggestion[] {
      const available = this.$cStore.employeeSearch.availableSuggestions;
      const selected = this.selectedSuggestions;
      return available.filter(suggestion => !find(selected, matches(suggestion)));
    },
    selectedSuggestions(): SearchSuggestion[] {
      return this.$cStore.employeeSearch.selectedSuggestions;
    },
    storeQuerySuggestions(): SearchSuggestion[] {
      const storeQuery = this.$cStore.routeQuery.query || {};
      const suggestions = this.mapQueriesToSuggestions(storeQuery);
      return uniqWith(suggestions, isEqual);
    }
  },
  mounted() {
    this.selected = this.selectedSuggestions;
    this.parseRouteQuery(this.$route.query);
    this.pushStoreQueryToSelected();
  },
  methods: {
    select(value: SearchSuggestion[]) {
      this.$cStore.employeeSearch.SET_SUGGESTIONS(value);
    },
    getSuggestionOptionKey(option: SearchSuggestion) {
      return option.object_type + "-" + option.match;
    },
    mapQueriesToSuggestions(queries: AugmentedRouterQuery): SearchSuggestion[] {
      let allSuggestions: SearchSuggestion[] = [];
      SUGGESTIONS_KEYS.forEach(suggestionKey => {
        let queryValue = queries[suggestionKey];
        let suggestions = this.mapQueryValueToSuggestions(queryValue, suggestionKey);
        if (suggestions.length) {
          allSuggestions = [...allSuggestions, ...suggestions];
        }
      });
      return allSuggestions;
    },
    mapQueryValueToSuggestions(
      queryValue: AugmentedRouterQueryValue,
      key: SearchSuggestionsTypeString
    ): SearchSuggestion[] {
      let suggestions: SearchSuggestion[] = [];
      if (Array.isArray(queryValue)) {
        queryValue.forEach(value => {
          let s = this.mapQueryValueToSuggestions(value, key);
          if (s.length) {
            suggestions = [...suggestions, ...s];
          }
        });
      } else if (queryValue) {
        suggestions.push({
          match: queryValue,
          object_type: SearchSuggestionsType[key]
        });
      }
      return suggestions;
    },
    groupSuggestions(suggestions: SearchSuggestion[]): SearchSuggestionsGrouped {
      return suggestions.reduce((total, cur) => {
        let currentTypeSuggestions = total[cur.object_type] || [];
        currentTypeSuggestions.push(cur.match);
        total[cur.object_type] = currentTypeSuggestions;
        return total;
      }, {} as SearchSuggestionsGrouped);
    },
    getQueryBySuggestions(suggestions: SearchSuggestion[]) {
      const defaultQuery = zipObject(SUGGESTIONS_KEYS, "");
      const grouppedSuggestions = this.groupSuggestions(suggestions);
      return Object.assign(defaultQuery, grouppedSuggestions);
    },
    parseRouteQuery(query: Dictionary<string | (string | null)[]>) {
      const suggestions = this.mapQueriesToSuggestions(query);
      if (!isEqual(suggestions, this.storeQuerySuggestions)) {
        const suggestionsQuery = this.getQueryBySuggestions(suggestions);
        this.$cStore.routeQuery.ADD_QUERY(suggestionsQuery);
      }
    },
    pushSelectedToRouteQuery() {
      if (!isEqual(this.selectedSuggestions, this.storeQuerySuggestions)) {
        const query = this.getQueryBySuggestions(this.selectedSuggestions);
        this.$cStore.routeQuery.ADD_QUERY(query);
      }
    },
    pushStoreQueryToSelected() {
      if (!isEqual(this.storeQuerySuggestions, this.selectedSuggestions)) {
        this.select(this.storeQuerySuggestions);
      }
    }
  },
  components: {
    SearchSelect
  },
  watch: {
    selectedSuggestions(suggestions: SearchSuggestion[]) {
      this.selected = suggestions;
      this.pushSelectedToRouteQuery();
    },
    storeQuerySuggestions() {
      this.pushStoreQueryToSelected();
    }
  }
});
