




















//TODO: возможно стоит посмотреть в сторону Named views
import Vue from "vue";
export default Vue.extend({
  props: {
    pageTitle: String
  }
});
