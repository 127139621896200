






import Vue from "vue";
import mixins from "vue-typed-mixins";
import ComponentModsMixin from "@/mixins/component-mods.ts";
export default mixins(ComponentModsMixin).extend({
  data: () => ({
    cols: 0
  }),
  methods: {
    calcColsInRow(row: HTMLElement): number {
      return [...row.children].reduce((sum, col) => {
        let colspan = col.getAttribute("colspan") || "";
        let parsedColspan = parseInt(colspan) || 1;
        return sum + parsedColspan;
      }, 0);
    },
    calcTableColsCount(): void {
      // const allRows = this.$el.querySelectorAll("tr");
      // const directRows = [...allRows].filter(row => {
      //   const tableParent = row.closest("table");
      //   return tableParent && tableParent === this.$el;
      // });
      // const maxColsCount = directRows.reduce((max, row) => {
      //   const colsCount = this.calcColsInRow(row);
      //   if (colsCount > max) max = colsCount;
      //   return max;
      // }, 0);
      // return maxColsCount;
      let firstRow = this.$el.querySelector("tr");
      let colsCount = 0;
      if (firstRow) {
        colsCount = this.calcColsInRow(firstRow);
      }
      this.cols = colsCount;
    }
  },
  mounted() {
    this.calcTableColsCount();
  },
  updated() {
    this.calcTableColsCount();
  }
});
